<template>
  <div>
    <div class="banner">
      <div class="w1400">
        <div class="banner_content">
          <img src="~assets/img/appDownImg/txPhone.png" />
          <div class="downBox">
            <span>同鑫再生 APP</span>
            <span>专业的再生资源综合服务平台</span>
            <div class="down">
              <div class="btns">
                <!-- :class="{ btn: true, active: curryIndex == index }" -->
                <div
                  v-for="(item, index) in btns"
                  @click="downClick(index)"
                  class="btn active"
                >
                  <img :src="item.url" />
                  <span>{{ item.text }}</span>
                </div>
              </div>
              <img :src="btns[curryIndex].QRurl" class="QRcodeImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w1400 bottom">
      <img src="~assets/img/appDownImg/title.png" />
      <div class="kf">
        <img src="~assets/img/appDownImg/kf.png" />
        <span>开通服务请咨询：13329574939</span>
      </div>
      <div class="priceBox">
        <div v-for="item in packages" class="price_item">
          <div v-if="item.hot" class="hot">{{ item.hot }}</div>
          <div class="price_item_content">
            <div class="title">{{ item.title }}</div>
            <div class="price">
              ¥ <i>{{ item.price }}</i> /{{ item.unit || '年' }}
            </div>
            <div class="mark">{{ item.mark }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDown',
  data() {
    return {
      curryIndex: 0,
      btns: [
        {
          text: '安卓客户端',
          url: require('assets/img/appDownImg/android_icon.png'),
          QRurl: require('assets/img/supplyMarket/QRcode.png'),
        },
        {
          text: '苹果客户端',
          url: require('assets/img/appDownImg/ios_icon.png'),
          QRurl: require('assets/img/supplyMarket/QRcode.png'),
        },
      ],
      packages: [
        {
          hot: '限时优惠',
          title: 'APP会员',
          price: '500',
          mark: '文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍',
        },
        {
          title: '招标拍卖会员',
          price: '3000',
          mark: '文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍',
        },
        {
          hot: '爆款热销',
          title: '广告位',
          price: '26800',
          mark: '文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍',
        },
        {
          title: '行情分析会员',
          price: '3000',
          mark: '文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍',
        },
        {
          title: 'APP置顶推广',
          price: '2800',
          unit: '月',
          mark: '文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍文字介绍',
        },
      ],
    }
  },
  methods: {
    downClick(index) {
      this.curryIndex = index
    },
  },
}
</script>

<style scoped>
.banner {
  width: 100%;
  height: 660px;
  background-image: linear-gradient(to top right, #25449a, #2d4faa);
  margin-bottom: 240px;
}
.banner_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1000px;
  height: 900px;
  margin: 0 auto;
}
.banner_content > img {
  width: 500px;
  height: 636px;
}

.downBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #ffffff;
  padding-left: 50px;
  width: 550px;
  height: 425px;
}

.downBox > span:nth-child(1) {
  font-size: 50px;
  font-weight: bold;
}
.downBox > span:nth-child(2) {
  font-size: 31px;
  font-weight: 400;
}

.down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
  width: 360px;
  height: 280px;
}

.btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 180px;
  height: 150px;
}
.QRcodeImg {
  width: 150px;
  height: 150px;
}

.btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 175px;
  height: 60px;
  font-size: 20px;
  font-weight: 400;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

.btn:hover {
  background-color: #284cc7;
}

.active {
  background-color: #223c95;
}

.btn img {
  width: 27px;
  height: 32px;
}

.bottom {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 450px;
  padding-bottom: 150px;
}

.bottom > img {
  width: 800px;
}

.kf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  font-size: 25px;
  font-weight: 400;
  color: #000000;
}
.kf > img {
  width: 40px;
}

.priceBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 280px;
}

.price_item {
  position: relative;
  padding-top: 7px;
  width: 220px;
  height: 257px;
  background: #f69b9d;
  border-radius: 4px;
  cursor: pointer;
}

.price_item:hover {
  background: #e83033;
}

.price_item_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000000;
  text-align: center;
  padding: 30px 30px 40px;
  width: 220px;
  height: 250px;
  background: #fff;
  border: 2px solid #d5d5d5;
  border-top: none;
  border-radius: 4px;
}

.price_item:hover .price_item_content {
  border: 2px solid #e83033;
  border-top: none;
}

.hot {
  position: absolute;
  top: -25px;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 16px;
  width: 100px;
  height: 48px;
  background: #e83033;
  border-radius: 20px 0px 20px 0px;
}

.title {
  font-size: 22px;
}

.price {
  font-size: 16px;
}

.price i {
  font-style: normal;
  font-size: 35px;
  font-family: Microsoft YaHei;
  font-weight: 700;
}

.price_item:hover .price {
  color: #e83033;
}

.mark {
  color: #adadad;
}
</style>
